
import { defineComponent } from 'vue';
import PlayButton from '../shared/PlayButton.vue';

export default defineComponent({
    name: 'et-sentence',
    components: {
        'et-play-button': PlayButton
    },
    props: {
        lang: { type: String, required: true },
        sentence: { type: String, required: true },
        sound: { type: String }
    }
});
