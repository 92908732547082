import XLSX, { CellObject, WSKeys } from 'xlsx';

interface DictionaryState {
    workbook: XLSX.WorkBook;
}

export interface DictionaryItem {
    trId: string;
    trItem: string;
    image: string;
    trSound: string;
    plItem: string;
    sentences: {
        tr: string;
        trSound: string;
        pl: string;
    }[];
    memoryGameCategory: string;
}

const getSheetCell = (sheet: XLSX.WorkSheet) => (cell: CellObject | WSKeys | any) => sheet[cell] && sheet[cell].v && sheet[cell].v.trim();

const groomUrl = (url: string) => {
    const slashes = url.replaceAll('\\', '/').replace('//', '/');
    return slashes.startsWith('/')
        ? slashes
        : `/${slashes}`;
};

export const dictionaryStore = {
    state: {
        workbook: {}
    } as DictionaryState,
    mutations: {
        setWorkbook: (state: DictionaryState, workbook: XLSX.WorkBook) => state.workbook = workbook
    },
    getters: {
        getDictionary: (state: DictionaryState) => {
            const dictionary: DictionaryItem[] = [];
            if (!state.workbook.Sheets) {
                return dictionary;
            }

            const sheet = state.workbook.Sheets[state.workbook.SheetNames[0]];
            const getCell = getSheetCell(sheet);

            const range = sheet['!ref']
                ? XLSX.utils.decode_range(sheet['!ref'])
                : { s: { r: 1 }, e: { r: 10000 } };
            const rowCount = range.e.r - range.s.r;

            for (let rowNumber = 2; rowNumber < rowCount; rowNumber++) {
                if (getCell(`A${rowNumber}`)) {
                    const trId = getCell(`A${rowNumber}`);
                    const trItem = getCell(`B${rowNumber}`);
                    const image = getCell(`C${rowNumber}`) && getCell(`D${rowNumber}`)
                        ? (getCell(`C${rowNumber}`) + '/' + getCell(`D${rowNumber}`)).trim()
                        : '';
                    const trSound = (getCell(`E${rowNumber}`) || '');
                    const plItem = getCell(`F${rowNumber}`);

                    // sentences
                    const sentences = [];
                    if (getCell(`G${rowNumber}`)) {
                        sentences.push({
                            tr: getCell(`G${rowNumber}`),
                            trSound: (getCell(`H${rowNumber}`) || '').replaceAll('\\', '/'),
                            pl: getCell(`I${rowNumber}`)
                        });
                    }
                    if (getCell(`J${rowNumber}`)) {
                        sentences.push({
                            tr: getCell(`J${rowNumber}`),
                            trSound: (getCell(`K${rowNumber}`) || '').replaceAll('\\', '/'),
                            pl: getCell(`L${rowNumber}`)
                        });
                    }
                    if (getCell(`M${rowNumber}`)) {
                        sentences.push({
                            tr: getCell(`M${rowNumber}`),
                            trSound: (getCell(`N${rowNumber}`) || '').replaceAll('\\', '/'),
                            pl: getCell(`O${rowNumber}`)
                        });
                    }
                    if (getCell(`P${rowNumber}`)) {
                        sentences.push({
                            tr: getCell(`P${rowNumber}`),
                            trSound: (getCell(`Q${rowNumber}`) || '').replaceAll('\\', '/'),
                            pl: getCell(`R${rowNumber}`)
                        });
                    }
                    const memoryGameCategory = getCell(`S${rowNumber}`) || '';

                    dictionary.push({
                        trId,
                        trItem,
                        image: image ? groomUrl(image) : '',
                        trSound: groomUrl(trSound),
                        plItem,
                        sentences,
                        memoryGameCategory
                    });
                }
            }
            return dictionary;
        }
    },
    actions: {
    },
    modules: {
    }
};