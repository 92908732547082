import XLSX from 'xlsx';
import { httpCode, HttpMethod } from './http';

const SOUND_ROOT_FOLDER = process.env.NODE_ENV !== 'production'
    ? '.'
    : '/wp-content/uploads/slownik';

const fetchSpreadsheet = (): Promise<ArrayBuffer> => new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open(HttpMethod.GET, `${SOUND_ROOT_FOLDER}/dictionary.xlsx`, true);
    req.responseType = 'arraybuffer';

    req.onload = function () {
        if (req.status === httpCode.OK) {
            resolve(req.response);
        } else {
            const decoder = new TextDecoder('utf-8');
            const body = decoder.decode(new Uint8Array(req.response));
            reject(body);
        }
    };

    req.send();
});

export const fetchDictionary = async () => {    // returns workbook
    const arrayBuffer = await fetchSpreadsheet();
    if (arrayBuffer != null) {
        const content = new Uint8Array(arrayBuffer);
        return XLSX.read(content, { type: 'array' });
    }
};