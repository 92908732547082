
import { defineComponent } from 'vue';
import { IMG_ROOT_FOLDER } from '@/modules/dictionary-consts';

export default defineComponent({
    IMG_ROOT_FOLDER,

    name: 'et-illustration',
    props: {
        image: { type: String, required: true }
    }
});
