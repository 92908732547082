<template>
    <div :class="{ 'cover': !initiated, 'loading': loading }" @click="loading || initiated ? null : onFirstStart()">
        <div class="grid game-top-bar">
            <div class="column game-title">
                {{ $filters.t('Fortune wheel') }}
            </div>
            <div class="column bigger text-right">
                <span class="pr">
                    {{ $filters.t('Tries left') }}: <b class="pr">{{ triesLeft >= 0 ? triesLeft : 0 }}</b>
                    <button class="game-button"
                            :disabled="!hintActive"
                            @click="onHint">
                        {{ $filters.t('Hint') }}
                    </button>
                </span>
                <span class="pr">
                    {{ $filters.t('Time spent') }}: {{ timeSpent }}
                </span>
                <button class="game-button"
                        @click="onStartNewGame">
                    {{ $filters.t('New game') }}
                </button>
            </div>
        </div>

        <div class="fortune-wheel game-content">
            <div class="info">
                {{ $filters.t('Native phrase') }}: {{ gameConfig.phrase }} | {{ $filters.t('Native category') }}: {{ gameConfig.trCategory }}
            </div>
            <div class="game-won text-center"
                 v-if="gameWon">
                {{ $filters.t('You\'ve won!') }}
                <div>{{ gamesPlayed.won }} / {{ gamesPlayed.won + gamesPlayed.lost }} {{ $filters.t('games won so far') }} ({{ gamesLeftToPlay }} {{ $filters.t('phrase(s) left to solve') }})</div>
            </div>
            <div class="game-lost text-center"
                 v-if="gameLost">
                {{ $filters.t('You\'ve lost!') }}
                <div>{{ gamesPlayed.won }} / {{ gamesPlayed.won + gamesPlayed.lost }} {{ $filters.t('games won so far') }} ({{ gamesLeftToPlay }} {{ $filters.t('phrase(s) left to solve') }})</div>
            </div>
            <div class="guess-me">
                <span class="word"
                      v-for="(word, i) in trLetterBoxes"
                      :key="`word_${i}`">
                    <span class="letter-box"
                          v-for="(letterBox, j) in word"
                          :key="`letter_${j}`"
                          :class="{ 'revealed': letterBox.revealed }">
                        <span class="letter">
                            {{ letterBox.revealed || gameLost ? letterBox.letter : '' }}
                        </span>
                    </span>
                </span>
            </div>
            <hr/>
            <div class="alphabet">
                <span class="letter-box"
                      v-for="letter in $options.trAlphabet"
                      :key="letter">
                    <span class="letter"
                          :class="{ 'used': trLettersUsed.includes(letter) }"
                          @click="trLettersUsed.includes(letter) || gameWon || gameLost || onLetterClick(letter)">
                        {{ letter }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { timestampToHhMmSs } from '@/filters/timestampToHhMmSs';
import { shuffle } from '@/modules/utils';
import { defineComponent } from 'vue';

const commonConfig = {
    MAX_TRIES: 5,
    MAX_HINTS: Infinity
};
const locale = 'pl'; // TODO: calculate one used by WP

type LetterBox = {
    letter: string;
    revealed: boolean;
}

interface Phrase {
    phraseTr: string;
    phrasePl: string;
    categoryTr: string;
}
interface Config {
    maxTries: number;
    categoryPl: string;
    subcategoryPl: string;
    phrases: Phrase[];
}

export default defineComponent({
    trAlphabet: 'ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ'.split(''),

    name: 'et-fortune-wheel-view',
    data () {
        return {
            loading: true,
            initiated: false,
            config: null as Config | null,

            gamesPlayed: {
                won: 0,
                lost: 0
            },
            triesLeft: commonConfig.MAX_TRIES,
            hintsLeft: commonConfig.MAX_HINTS,
            startTime: new Date().getTime(),
            timeSpent: '00:00:00',
            gameConfig: {
                trCategory: '',
                trPhrase: '',
                phrase: ''
            },
            timer: undefined as number | undefined,
            trLettersUsed: [] as string[],
            trLettersGuessed: [] as string[],
            // phrasesUnusedYet: new Array(config.length).fill(0).map((_, index) => index)
            phrasesUnusedYet: [] as Array<number>
        };
    },
    computed: {
        trLetterBoxes (): LetterBox[][] {
            const trLetters = this.gameConfig.trPhrase
                .split(' ')
                .map((word) => word.split(''));
            return trLetters.map((word) => word.map((letter) => ({
                letter,
                revealed: this.trLettersGuessed.includes(letter)
            })));
        },
        gameWon (): boolean {
            return this.initiated && this.trLetterBoxes.reduce((
                wordRevealed: boolean,
                word: LetterBox[]
            ) => (wordRevealed && word.reduce((lettersRevealed: boolean, letterBox: LetterBox) => lettersRevealed && this.trLettersUsed.includes(letterBox.letter), true)), true);
        },
        gameLost (): boolean {
            return !this.gameWon && this.triesLeft <= 0;
        },
        hintActive (): boolean {
            return !this.gameLost && !this.gameWon;
        },
        gamesLeftToPlay (): number {
            return this.phrasesUnusedYet.length;
        }
    },
    mounted () {
        const srcFolder = process.env.NODE_ENV === 'production'
            ? '/wp-content/uploads/games/configs/fortune-wheel/xml/'
            : './uploads/games/configs/fortune-wheel/xml/';
        const configSrc = this.$el.parentNode.getAttribute('data-config-src');
        this.parseAndSetConfig(srcFolder, configSrc);
    },
    methods: {
        async parseAndSetConfig (srcFolder: string, configSrc: string) {
            let xmlDoc: any;
            const getProp = (
                doc: any
            ) => (
                propName: string
            ) => doc.getElementsByTagName(propName)[0].childNodes[0].nodeValue;
            const getPhraseProps = (
                doc: any
            ) => (
                propName: string
            ) => Array.from(doc.getElementsByTagName(propName)).map((node: any) => ({
                phraseTr: node.childNodes[0].nodeValue,
                categoryTr: node.getAttribute('author'),
                phrasePl: node.getAttribute('topic')
            }));

            const configResponse = await fetch(srcFolder + configSrc);
            const configXml = await configResponse.text();
            if (window.DOMParser) {
                const parser = new DOMParser();
                xmlDoc = parser.parseFromString(configXml, 'text/xml');
            } else { // Internet Explorer
                xmlDoc = new ActiveXObject('Microsoft.XMLDOM'); // eslint-disable-line no-undef
                xmlDoc.async = false;
                xmlDoc.loadXML(configXml);
            }

            const contentSrc = getProp(xmlDoc)('url');
            // const game = getProp(xmlDoc)('category');
            const categoryPl = getProp(xmlDoc)('description');

            const contentResponse = await fetch(contentSrc.replace('content/gry/hasla/xml/', srcFolder));
            const contentXml = await contentResponse.text();
            if (window.DOMParser) {
                const parser = new DOMParser();
                xmlDoc = parser.parseFromString(contentXml, 'text/xml');
            } else { // Internet Explorer
                xmlDoc = new ActiveXObject('Microsoft.XMLDOM'); // eslint-disable-line no-undef
                xmlDoc.async = false;
                xmlDoc.loadXML(contentXml);
            }

            const subcategoryPl = getProp(xmlDoc)('id');
            const phrases = getPhraseProps(xmlDoc)('word');
            const maxTries = xmlDoc.getElementsByTagName('hangmanPuzzle')[0].getAttribute('tries');
            this.config = {
                maxTries,
                categoryPl,
                subcategoryPl,
                phrases
            } as Config;
            this.phrasesUnusedYet = new Array(this.config.phrases.length).fill(0).map((_, index) => index);
            this.loading = false;
        },
        onStartNewGame () {
            if (!this.gamesLeftToPlay) {
                this.gamesPlayed.won = 0;
                this.gamesPlayed.lost = 0;
                this.phrasesUnusedYet = new Array(this.config!.phrases.length).fill(0).map((_, index) => index);
            }

            this.startTime = new Date().getTime();
            this.timeSpent = '00:00:00';
            this.triesLeft = this.config!.maxTries || commonConfig.MAX_TRIES;

            const randomPhraseIndex = [ ...this.phrasesUnusedYet ].sort(shuffle)[0];
            this.phrasesUnusedYet = this.phrasesUnusedYet.filter((configIndex) => configIndex !== randomPhraseIndex);
            this.gameConfig = {
                ...this.config!.phrases[randomPhraseIndex],
                trPhrase: this.config!.phrases[randomPhraseIndex].phraseTr,
                phrase: (this.config!.phrases[randomPhraseIndex] as any)[`phrase${locale[0].toUpperCase() + locale[1]}`],
                trCategory: this.config!.phrases[randomPhraseIndex].categoryTr
            };
            this.trLettersUsed = [];
            this.trLettersGuessed = [];

            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                this.timeSpent = timestampToHhMmSs(new Date().getTime() - this.startTime);
            }, 500);
        },
        onHint () {
            const lettersThatCanBeHinted = this.gameConfig.trPhrase
                .split('')
                .filter((letter) => letter !== ' ' && !this.trLettersUsed.includes(letter));
            const letterToHint = lettersThatCanBeHinted.sort(shuffle)[0];
            this.onLetterClick(letterToHint);
        },
        onLetterClick (letter: string) {
            this.trLettersUsed.push(letter);
            if (this.gameConfig.trPhrase.indexOf(letter) !== -1) {
                this.trLettersGuessed.push(letter);
            } else {
                this.triesLeft--;
            }
            if (this.gameWon) {
                this.gamesPlayed.won++;
                clearInterval(this.timer);
                this.timer = 0;
            } else if (this.gameLost) {
                this.gamesPlayed.lost++;
                clearInterval(this.timer);
                this.timer = 0;
            }
        },

        onFirstStart () {
            this.initiated = true;
            this.onStartNewGame();
        }
    }
});
</script>

<style lang="scss" scoped>
.cover,
.loading {
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
    }

    &::after {
        content: '►';
        font-size: 48px;
        color: white;
        background: #62a1ac;
        text-align: center;
        width: 108px;
        padding: 20px 20px 20px 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.loading {
    cursor: default;

    &::after {
        content: '...';
        padding: 20px 25px 20px 25px
    }
}
</style>