
import { timestampToHhMmSs } from '@/filters/timestampToHhMmSs';
import { shuffle } from '@/modules/utils';
import { defineComponent } from 'vue';

const commonConfig = {
    MAX_TRIES: 5,
    MAX_HINTS: Infinity
};
const locale = 'pl'; // TODO: calculate one used by WP

type LetterBox = {
    letter: string;
    revealed: boolean;
}

interface Phrase {
    phraseTr: string;
    phrasePl: string;
    categoryTr: string;
}
interface Config {
    maxTries: number;
    categoryPl: string;
    subcategoryPl: string;
    phrases: Phrase[];
}

export default defineComponent({
    trAlphabet: 'ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ'.split(''),

    name: 'et-fortune-wheel-view',
    data () {
        return {
            loading: true,
            initiated: false,
            config: null as Config | null,

            gamesPlayed: {
                won: 0,
                lost: 0
            },
            triesLeft: commonConfig.MAX_TRIES,
            hintsLeft: commonConfig.MAX_HINTS,
            startTime: new Date().getTime(),
            timeSpent: '00:00:00',
            gameConfig: {
                trCategory: '',
                trPhrase: '',
                phrase: ''
            },
            timer: undefined as number | undefined,
            trLettersUsed: [] as string[],
            trLettersGuessed: [] as string[],
            // phrasesUnusedYet: new Array(config.length).fill(0).map((_, index) => index)
            phrasesUnusedYet: [] as Array<number>
        };
    },
    computed: {
        trLetterBoxes (): LetterBox[][] {
            const trLetters = this.gameConfig.trPhrase
                .split(' ')
                .map((word) => word.split(''));
            return trLetters.map((word) => word.map((letter) => ({
                letter,
                revealed: this.trLettersGuessed.includes(letter)
            })));
        },
        gameWon (): boolean {
            return this.initiated && this.trLetterBoxes.reduce((
                wordRevealed: boolean,
                word: LetterBox[]
            ) => (wordRevealed && word.reduce((lettersRevealed: boolean, letterBox: LetterBox) => lettersRevealed && this.trLettersUsed.includes(letterBox.letter), true)), true);
        },
        gameLost (): boolean {
            return !this.gameWon && this.triesLeft <= 0;
        },
        hintActive (): boolean {
            return !this.gameLost && !this.gameWon;
        },
        gamesLeftToPlay (): number {
            return this.phrasesUnusedYet.length;
        }
    },
    mounted () {
        const srcFolder = process.env.NODE_ENV === 'production'
            ? '/wp-content/uploads/games/configs/fortune-wheel/xml/'
            : './uploads/games/configs/fortune-wheel/xml/';
        const configSrc = this.$el.parentNode.getAttribute('data-config-src');
        this.parseAndSetConfig(srcFolder, configSrc);
    },
    methods: {
        async parseAndSetConfig (srcFolder: string, configSrc: string) {
            let xmlDoc: any;
            const getProp = (
                doc: any
            ) => (
                propName: string
            ) => doc.getElementsByTagName(propName)[0].childNodes[0].nodeValue;
            const getPhraseProps = (
                doc: any
            ) => (
                propName: string
            ) => Array.from(doc.getElementsByTagName(propName)).map((node: any) => ({
                phraseTr: node.childNodes[0].nodeValue,
                categoryTr: node.getAttribute('author'),
                phrasePl: node.getAttribute('topic')
            }));

            const configResponse = await fetch(srcFolder + configSrc);
            const configXml = await configResponse.text();
            if (window.DOMParser) {
                const parser = new DOMParser();
                xmlDoc = parser.parseFromString(configXml, 'text/xml');
            } else { // Internet Explorer
                xmlDoc = new ActiveXObject('Microsoft.XMLDOM'); // eslint-disable-line no-undef
                xmlDoc.async = false;
                xmlDoc.loadXML(configXml);
            }

            const contentSrc = getProp(xmlDoc)('url');
            // const game = getProp(xmlDoc)('category');
            const categoryPl = getProp(xmlDoc)('description');

            const contentResponse = await fetch(contentSrc.replace('content/gry/hasla/xml/', srcFolder));
            const contentXml = await contentResponse.text();
            if (window.DOMParser) {
                const parser = new DOMParser();
                xmlDoc = parser.parseFromString(contentXml, 'text/xml');
            } else { // Internet Explorer
                xmlDoc = new ActiveXObject('Microsoft.XMLDOM'); // eslint-disable-line no-undef
                xmlDoc.async = false;
                xmlDoc.loadXML(contentXml);
            }

            const subcategoryPl = getProp(xmlDoc)('id');
            const phrases = getPhraseProps(xmlDoc)('word');
            const maxTries = xmlDoc.getElementsByTagName('hangmanPuzzle')[0].getAttribute('tries');
            this.config = {
                maxTries,
                categoryPl,
                subcategoryPl,
                phrases
            } as Config;
            this.phrasesUnusedYet = new Array(this.config.phrases.length).fill(0).map((_, index) => index);
            this.loading = false;
        },
        onStartNewGame () {
            if (!this.gamesLeftToPlay) {
                this.gamesPlayed.won = 0;
                this.gamesPlayed.lost = 0;
                this.phrasesUnusedYet = new Array(this.config!.phrases.length).fill(0).map((_, index) => index);
            }

            this.startTime = new Date().getTime();
            this.timeSpent = '00:00:00';
            this.triesLeft = this.config!.maxTries || commonConfig.MAX_TRIES;

            const randomPhraseIndex = [ ...this.phrasesUnusedYet ].sort(shuffle)[0];
            this.phrasesUnusedYet = this.phrasesUnusedYet.filter((configIndex) => configIndex !== randomPhraseIndex);
            this.gameConfig = {
                ...this.config!.phrases[randomPhraseIndex],
                trPhrase: this.config!.phrases[randomPhraseIndex].phraseTr,
                phrase: (this.config!.phrases[randomPhraseIndex] as any)[`phrase${locale[0].toUpperCase() + locale[1]}`],
                trCategory: this.config!.phrases[randomPhraseIndex].categoryTr
            };
            this.trLettersUsed = [];
            this.trLettersGuessed = [];

            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                this.timeSpent = timestampToHhMmSs(new Date().getTime() - this.startTime);
            }, 500);
        },
        onHint () {
            const lettersThatCanBeHinted = this.gameConfig.trPhrase
                .split('')
                .filter((letter) => letter !== ' ' && !this.trLettersUsed.includes(letter));
            const letterToHint = lettersThatCanBeHinted.sort(shuffle)[0];
            this.onLetterClick(letterToHint);
        },
        onLetterClick (letter: string) {
            this.trLettersUsed.push(letter);
            if (this.gameConfig.trPhrase.indexOf(letter) !== -1) {
                this.trLettersGuessed.push(letter);
            } else {
                this.triesLeft--;
            }
            if (this.gameWon) {
                this.gamesPlayed.won++;
                clearInterval(this.timer);
                this.timer = 0;
            } else if (this.gameLost) {
                this.gamesPlayed.lost++;
                clearInterval(this.timer);
                this.timer = 0;
            }
        },

        onFirstStart () {
            this.initiated = true;
            this.onStartNewGame();
        }
    }
});
