
import { defineComponent } from 'vue';
import PlayButton from '../shared/PlayButton.vue';
import { SOUND_ROOT_FOLDER } from '../../modules/dictionary-consts';

export default defineComponent({
    SOUND_ROOT_FOLDER,

    name: 'et-word',
    components: {
        'et-play-button': PlayButton
    },
    props: {
        lang: { type: String },
        word: { type: String, required: true },
        sound: { type: String }
    }
});
