
import { defineComponent } from 'vue';
import { SOUND_ROOT_FOLDER } from '@/modules/dictionary-consts';

export default defineComponent({
    SOUND_ROOT_FOLDER,

    name: 'et-play-button',
    props: {
        sound: { type: String, required: true }
    },
    methods: {
        onPlay () {
            (this.$refs[this.sound] as any).play();
        }
    }
});
