<template>
    <div>
        <div class="grid game-top-bar">
            <div class="column game-title">
                {{ $filters.t('Pairs') }}
            </div>
            <div class="column bigger text-right">
                <span class="pr">
                    {{ $filters.t('Tries') }}: <strong class="pr">{{ tries }}</strong>
                </span>
                <span class="pr">
                    {{ $filters.t('Time left') }}: {{ timeLeftFormatted }}
                </span>
                <button class="game-button"
                @click="state = $options.State.INITIAL">
                    {{ $filters.t('New game') }}
                </button>
            </div>
        </div>

        <div class="memory game-content"
        v-if="!category || !dictionary.length">
            {{ $filters.t('Loading…') }}
        </div>
        <div class="memory game-content"
        v-else-if="availableWords.length < 4">
            {{ $filters.t('Not enough words in category to make a game.') }}
        </div>
        <div class="memory game-content"
        v-else>
            <div class="game-won text-center"
                v-if="gameWon">
                {{ $filters.t('You\'ve won!') }}
            </div>
            <div class="game-lost text-center"
                v-if="gameLost">
                {{ $filters.t('You\'ve run out of time!') }}
            </div>
            <div class="tiles">
                <div :style="{ width: `calc(${100 / maxSizeNormalized.x + '%'} - ${(maxSizeNormalized.x - 1) * 0.5 / maxSizeNormalized.x}em)` }"
                v-for="(tile, index) in tiles" :key="`t_${tile}`">
                    <div class="tile"
                    :class="{ guessed: tile.guessed }"
                    @click="onClick(index)">
                        <div class="content"
                        :class="{
                            hidden: !revealed.includes(index),
                            image: tile.type === $options.TileType.IMAGE
                        }"
                        :style="{ backgroundImage: tile.type === $options.TileType.IMAGE ? `url(${$options.IMG_ROOT_FOLDER}${encodeURI(tile.image)})` : '' }">
                            <template
                            v-if="tile.type === $options.TileType.TEXT">{{ tile.trItem }}</template>
                        </div>
                        <div class="content q-mark"
                        :class="{ hidden: revealed.includes(index) || tile.guessed }">
                            ?
                        </div>
                        <div class="content check-mark"
                        :class="{ hidden: revealed.includes(index) || !tile.guessed }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { DictionaryItem } from '@/store/stores/dictionary';
import { shuffle } from '@/modules/utils';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { IMG_ROOT_FOLDER } from '@/modules/dictionary-consts';

const TIME_TILL_LOST = 300; // 5 min

let gameTimer: ReturnType<typeof setTimeout> | null = null;
let tileTimer: ReturnType<typeof setTimeout> | null = null;
const TILE_DELAY = 1500;

enum State {
    INITIAL = 'initial',
    IN_GAME = 'in_game',
    ENDED = 'ended'
}

enum TileType {
    TEXT = 'text',
    IMAGE = 'image'
}

interface Tile extends DictionaryItem {
    type: TileType;
    guessed: boolean;
}

interface Coords {
    x: number;
    y: number;
}

interface Data {
    category: string;
    started: number;   // timestamp
    timeLeft: number; // seconds
    tries: number;
    tiles: Tile[];
    revealed: number[];
    state: State;
}

export default defineComponent({
    TileType,
    State,
    IMG_ROOT_FOLDER,

    name: 'et-memory-view',
    data (): Data {
        return {
            category: '',
            started: 0,
            timeLeft: TIME_TILL_LOST,
            tries: 0,
            tiles: [],
            revealed: [],
            state: State.INITIAL
        };
    },
    computed: {
        ...mapGetters({
            dictionary: 'getDictionary'
        }),

        dictionarySansDuplicates (): DictionaryItem[] {
            return this.dictionary.filter((dictionaryItem: DictionaryItem, index: number) => {
                const firstIndex = this.dictionary.findIndex((item: DictionaryItem) => item.trItem === dictionaryItem.trItem);
                return firstIndex === index;
            });
        },
        availableWords (): DictionaryItem[] {
            return this.category
                ? this.dictionarySansDuplicates
                    .filter((dictionaryItem: DictionaryItem) => dictionaryItem.memoryGameCategory.startsWith(this.category))
                : [];
        },
        maxSizeNormalized (): Coords {
            const tiles = this.availableWords.length * 2;
            /* eslint-disable no-magic-numbers */
            switch (true) {
                case tiles >= 6 * 4:
                    return { x: 6, y: 4 };
                case tiles >= 20:
                    return { x: 5, y: 4 };
                case tiles >= 18:
                    return { x: 6, y: 3 };
                case tiles >= 16:
                    return { x: 4, y: 4 };
                case tiles >= 12:
                    return { x: 4, y: 3 };
                case tiles >= 10:
                    return { x: 5, y: 2 };
                default:
                    return { x: 4, y: 2 };
            }
            /* eslint-enable no-magic-numbers */
        },
        timeLeftFormatted (): string {
            const seconds = this.timeLeft % 60;
            const minutes = (this.timeLeft - seconds) / 60 % 60;
            return [
                minutes.toString().padStart(2, '0'),
                seconds.toString().padStart(2, '0')
            ].join(':');
        },

        anyLeftToGuess (): boolean {
            return this.tiles.some((tile) => !tile.guessed);
        },
        gameWon (): boolean {
            return this.state === State.ENDED && !this.anyLeftToGuess;
        },
        gameLost (): boolean {
            return this.state === State.ENDED && this.anyLeftToGuess;
        }
    },
    mounted () {
        const rootEl = this.$root!.$el.closest && this.$root!.$el.closest('.eturecki-game');
        this.category = rootEl && rootEl.getAttribute('data-category');
        if (this.dictionarySansDuplicates.length) {
            this.onStartNewGame();
        }
    },
    watch: {
        dictionarySansDuplicates () {
            this.onStartNewGame();
        },
        state () {
            if (this.state === State.INITIAL) {
                this.onStartNewGame();
            } else if (this.state === State.IN_GAME) {
                this.started = new Date().getTime();
                this.updateGameTime();
            } else if (this.state === State.ENDED) {
                console.log('state == ENDED');
                this.updateGameTime();
                gameTimer && clearTimeout(gameTimer);
                gameTimer = null;
            }
        }
    },
    methods: {
        onStartNewGame () {
            const pairsCount = this.maxSizeNormalized.x * this.maxSizeNormalized.y / 2;
            const words = [ ...this.availableWords ].sort(shuffle).slice(0, pairsCount);
            this.tiles = [
                ...words.map((dictionaryItem) => ({ ...dictionaryItem, type: TileType.TEXT, guessed: false })),
                ...words.map((dictionaryItem) => ({ ...dictionaryItem, type: TileType.IMAGE, guessed: false }))
            ].sort(shuffle);
            this.tries = 0;
            this.started = 0;
            this.revealed = [];
            tileTimer && clearTimeout(tileTimer);
            tileTimer = null;
            gameTimer && clearTimeout(gameTimer);
            gameTimer = null;
            this.timeLeft = TIME_TILL_LOST;
        },

        clearRevealed () {
            tileTimer && clearTimeout(tileTimer);
            tileTimer = null;
            this.revealed = [];
        },
        updateGameTime () {
            console.log('updateGameTime');
            const now = new Date().getTime();
            this.timeLeft = TIME_TILL_LOST - Math.floor((now - this.started) / 1000);
            if (this.timeLeft > 0) {
                gameTimer && clearTimeout(gameTimer);
                gameTimer = setTimeout(this.updateGameTime, 1000);
            } else {
                this.state = State.ENDED;
                tileTimer && clearTimeout(tileTimer);
                tileTimer = setTimeout(this.clearRevealed, TILE_DELAY);
            }
        },
        onClick (index: number) {
            if (this.tiles[index].guessed || this.state === State.ENDED || tileTimer) {
                return;
            }
            if (this.state === State.INITIAL) {
                this.state = State.IN_GAME;
            }
            if (this.revealed.length === 2 || this.revealed.includes(index)) {
                this.clearRevealed();
            }
            this.revealed.push(index);
            if (this.revealed.length === 2) {
                this.tries++;
                if (this.tiles[this.revealed[0]].trItem === this.tiles[this.revealed[1]].trItem) {
                    this.tiles[this.revealed[0]].guessed = true;
                    this.tiles[this.revealed[1]].guessed = true;
                }
                tileTimer && clearTimeout(tileTimer);
                tileTimer = setTimeout(this.clearRevealed, TILE_DELAY);
            }
            if (!this.anyLeftToGuess) {
                this.state = State.ENDED;
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.5em;
}
.tile {
    padding-bottom: 100%;
    position: relative;
    border: 3px solid #62a1ac;
    cursor: pointer;

    .content {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        transition: opacity 0.25s;

        &.image {
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }

        &.hidden {
            opacity: 0;
        }

        &.check-mark {
            box-sizing: border-box;
            background: url('https://eturecki.pl/wp-content/uploads/2020/07/logo1.png') no-repeat center;
            background-size: contain;
            border: 1rem solid transparent;
            height: 100%;
        }

        &.q-mark {
            font-family: sans-serif;
            font-size: 500%;
            color: rgba(#62a1ac, 0.1);

            @media (max-width: 700px) {
                font-size: 400%;
            }

            @media (max-width: 500px) {
                font-size: 300%;
            }
        }
    }
}
.guessed {
    background: rgba(#62a1ac, 0.25);
    cursor: default;
}
.game-lost,
.game-won {
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 1rem;
}
</style>