
import { defineComponent } from 'vue';
import Sentence from './Sentence.vue';

export default defineComponent({
    name: 'et-sentences',
    components: {
        'et-sentence': Sentence
    },
    props: {
        sentences: { type: Array, required: true },
        fromLang: { type: String },
        toLang: { type: String }
    },
    computed: {
        bothPresent (): (sentence: Record<string, string>) => boolean {
            return (sentence: Record<string, string>) => Boolean(this.fromLang && sentence[this.fromLang] && this.toLang && sentence[this.toLang]);
        }
    }
});
