<template>
    <div>
        <div class="grid game-top-bar">
            <div class="column game-title">
                {{ $filters.t('Dictionary') }}
            </div>
        </div>

        <div class="dictionary game-content" v-if="dictionaryItem">
            <component :is="component"
            :dictionary-item="dictionaryItem" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayButton from '@/components/shared/PlayButton.vue';
import { CardType } from '@/modules/dictionary-consts';
import TrPl from '@/components/dictionary/card-types/TrPl.vue';
import PlTr from '@/components/dictionary/card-types/PlTr.vue';
import QuestionTrPl from '@/components/dictionary/card-types/QuestionTrPl.vue';
import QuestionPlTr from '@/components/dictionary/card-types/QuestionPlTr.vue';

export default {
    name: 'et-dictionary-view',
    components: {
        'et-play-button': PlayButton,
        TrPl,
        PlTr,
        QuestionTrPl
    },
    data () {
        return {
            trId: '',
            cardType: CardType.TR_PL
        };
    },
    computed: {
        ...mapGetters({
            dictionary: 'getDictionary'
        }),

        dictionaryItem () {
            return this.trId
                ? this.dictionary.find((dictionaryItem) => dictionaryItem.trId === this.trId)
                : '';
        },
        component () {
            switch (this.cardType) {
                case CardType.PL_TR:
                    return PlTr;
                case CardType.QUESTION_TR_PL:
                    return QuestionTrPl;
                case CardType.QUESTION_PL_TR:
                    return QuestionPlTr;
                case CardType.TR_PL:
                default:
                    return TrPl;
            }
        }
    },
    mounted () {
        const rootEl = this.$root.$el.closest && this.$root.$el.closest('.eturecki-game');
        this.trId = rootEl && rootEl.getAttribute('data-word-id');
        this.cardType = rootEl && rootEl.getAttribute('data-card-type');
    }
};
</script>