export const SOUND_ROOT_FOLDER = process.env.NODE_ENV !== 'production'
    ? 'https://eturecki.pl/wp-content/uploads/slownik/Wszystkie nagrania/'
    : '/wp-content/uploads/slownik/Wszystkie nagrania/';

export const IMG_ROOT_FOLDER = process.env.NODE_ENV !== 'production'
    ? `https://eturecki.pl/wp-content/uploads/slownik/Shutterstock`
    : '/wp-content/uploads/slownik/Shutterstock';

export enum CardType {
    TR_PL = 'tr-pl',
    PL_TR = 'pl-tr',
    QUESTION_TR_PL = 'question-tr-pl',
    QUESTION_PL_TR = 'question-pl-tr'
}