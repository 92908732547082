import { GameType } from '@/router';
import { createStore } from 'vuex';
import { dictionaryStore } from './stores/dictionary';

const defaultStore = {
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
};

const stores = {
    [GameType.DICTIONARY]: dictionaryStore,
    [GameType.MEMORY]: dictionaryStore // for now the same stor will do
} as Record<GameType, object>;

export default (type: GameType) => createStore(stores[type] || defaultStore);
