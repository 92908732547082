import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import FortuneWheelGame from '../views/FortuneWheelGame.vue';
import MemoryGame from '../views/MemoryGame.vue';
import Dictionary from '../views/Dictionary.vue';

export enum GameType {
    FORTUNE_WHEEL = 'fortune-wheel',
    MEMORY = 'memory',
    DICTIONARY = 'dictionary'
}

const gameRoute = {
    [GameType.FORTUNE_WHEEL]: [
        {
            path: '/',
            name: 'fortune-wheel',
            component: FortuneWheelGame
        }
    ] as Array<RouteRecordRaw>,
    [GameType.MEMORY]: [
        {
            path: '/',
            name: 'memory',
            component: MemoryGame
        }
    ] as Array<RouteRecordRaw>,
    [GameType.DICTIONARY]: [
        {
            path: '/',
            name: 'dictionary',
            component: Dictionary
        }
    ] as Array<RouteRecordRaw>
};

const router = (gameType: GameType) => createRouter({
    history: createWebHashHistory(),
    routes: gameRoute[gameType]
});

export default router;
