type Locale = 'pl' | 'en';
type Dictionary<T> = {
    [key: string]: T;
};

/* eslint-disable quote-props */

const translations: { [locale in Locale]: Dictionary<string> } = {
    pl: {
        // Fortune wheel
        'Fortune wheel': 'Koło fortuny',
        'Tries left': 'Pozostało prób',
        'Time spent': 'Czas',
        'Hint': 'Podpowiedź',
        'New game': 'Nowa gra',
        'Native phrase': 'Hasło',
        'Native category': 'Kategoria',
        'You\'ve won!': 'Wygrałeś!',
        'You\'ve lost!': 'Zabrakło prób...',
        'games won so far': 'wygranych gier',
        'phrase(s) left to solve': 'zdań pozostało do odgadnięcia',

        // Dictionary
        'Dictionary': 'Słownictwo',
        'Enter translation': 'Podaj tłumaczenie',
        'Getting close…': 'Ciepło, ciepło…',
        'Perfect!': 'Brawo!',

        // Memory
        'Pairs': 'Pary',
        'Tries': 'Próby',
        'Time left': 'Pozostało',
        'You\'ve run out of time!': 'Skończył się czas!',
        'Not enough words in category to make a game.': 'Za mało słów w kategorii, by utworzyć grę.',
        'Loading…': 'Ładuję…'
    },
    'en': {

    }
};

// TODO: Future translation function, depending on WP page version
export const t = (locale: string) => (str: string) => translations[locale as Locale][str] || str;
