export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export enum httpCode {
    OK = 200,
    NOT_FOUND = 404
}