<template>
    <div>
        <div class="usage-example"
        v-for="sentence in sentences"
        :key="sentence.tr">
            <et-sentence
            v-if="sentence[fromLang]"
            :class="{ 'pale': fromLang !== 'tr', 'emphasised': fromLang === 'tr' }"
            :lang="fromLang"
            :sentence="sentence[fromLang]"
            :sound="sentence[`${fromLang}Sound`]" />

            <et-sentence
            v-if="sentence[toLang]"
            :class="{ 'pale': toLang !== 'tr', 'emphasised': toLang === 'tr' }"
            :lang="toLang"
            :sentence="sentence[toLang]"
            :sound="sentence[`${toLang}Sound`]" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Sentence from './Sentence.vue';

export default defineComponent({
    name: 'et-sentences',
    components: {
        'et-sentence': Sentence
    },
    props: {
        sentences: { type: Array, required: true },
        fromLang: { type: String },
        toLang: { type: String }
    },
    computed: {
        bothPresent (): (sentence: Record<string, string>) => boolean {
            return (sentence: Record<string, string>) => Boolean(this.fromLang && sentence[this.fromLang] && this.toLang && sentence[this.toLang]);
        }
    }
});
</script>

<style lang="scss" scoped>
.pale {
    font-size: 90%;
}
</style>