
import { DictionaryItem } from '@/store/stores/dictionary';
import { shuffle } from '@/modules/utils';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { IMG_ROOT_FOLDER } from '@/modules/dictionary-consts';

const TIME_TILL_LOST = 300; // 5 min

let gameTimer: ReturnType<typeof setTimeout> | null = null;
let tileTimer: ReturnType<typeof setTimeout> | null = null;
const TILE_DELAY = 1500;

enum State {
    INITIAL = 'initial',
    IN_GAME = 'in_game',
    ENDED = 'ended'
}

enum TileType {
    TEXT = 'text',
    IMAGE = 'image'
}

interface Tile extends DictionaryItem {
    type: TileType;
    guessed: boolean;
}

interface Coords {
    x: number;
    y: number;
}

interface Data {
    category: string;
    started: number;   // timestamp
    timeLeft: number; // seconds
    tries: number;
    tiles: Tile[];
    revealed: number[];
    state: State;
}

export default defineComponent({
    TileType,
    State,
    IMG_ROOT_FOLDER,

    name: 'et-memory-view',
    data (): Data {
        return {
            category: '',
            started: 0,
            timeLeft: TIME_TILL_LOST,
            tries: 0,
            tiles: [],
            revealed: [],
            state: State.INITIAL
        };
    },
    computed: {
        ...mapGetters({
            dictionary: 'getDictionary'
        }),

        dictionarySansDuplicates (): DictionaryItem[] {
            return this.dictionary.filter((dictionaryItem: DictionaryItem, index: number) => {
                const firstIndex = this.dictionary.findIndex((item: DictionaryItem) => item.trItem === dictionaryItem.trItem);
                return firstIndex === index;
            });
        },
        availableWords (): DictionaryItem[] {
            return this.category
                ? this.dictionarySansDuplicates
                    .filter((dictionaryItem: DictionaryItem) => dictionaryItem.memoryGameCategory.startsWith(this.category))
                : [];
        },
        maxSizeNormalized (): Coords {
            const tiles = this.availableWords.length * 2;
            /* eslint-disable no-magic-numbers */
            switch (true) {
                case tiles >= 6 * 4:
                    return { x: 6, y: 4 };
                case tiles >= 20:
                    return { x: 5, y: 4 };
                case tiles >= 18:
                    return { x: 6, y: 3 };
                case tiles >= 16:
                    return { x: 4, y: 4 };
                case tiles >= 12:
                    return { x: 4, y: 3 };
                case tiles >= 10:
                    return { x: 5, y: 2 };
                default:
                    return { x: 4, y: 2 };
            }
            /* eslint-enable no-magic-numbers */
        },
        timeLeftFormatted (): string {
            const seconds = this.timeLeft % 60;
            const minutes = (this.timeLeft - seconds) / 60 % 60;
            return [
                minutes.toString().padStart(2, '0'),
                seconds.toString().padStart(2, '0')
            ].join(':');
        },

        anyLeftToGuess (): boolean {
            return this.tiles.some((tile) => !tile.guessed);
        },
        gameWon (): boolean {
            return this.state === State.ENDED && !this.anyLeftToGuess;
        },
        gameLost (): boolean {
            return this.state === State.ENDED && this.anyLeftToGuess;
        }
    },
    mounted () {
        const rootEl = this.$root!.$el.closest && this.$root!.$el.closest('.eturecki-game');
        this.category = rootEl && rootEl.getAttribute('data-category');
        if (this.dictionarySansDuplicates.length) {
            this.onStartNewGame();
        }
    },
    watch: {
        dictionarySansDuplicates () {
            this.onStartNewGame();
        },
        state () {
            if (this.state === State.INITIAL) {
                this.onStartNewGame();
            } else if (this.state === State.IN_GAME) {
                this.started = new Date().getTime();
                this.updateGameTime();
            } else if (this.state === State.ENDED) {
                console.log('state == ENDED');
                this.updateGameTime();
                gameTimer && clearTimeout(gameTimer);
                gameTimer = null;
            }
        }
    },
    methods: {
        onStartNewGame () {
            const pairsCount = this.maxSizeNormalized.x * this.maxSizeNormalized.y / 2;
            const words = [ ...this.availableWords ].sort(shuffle).slice(0, pairsCount);
            this.tiles = [
                ...words.map((dictionaryItem) => ({ ...dictionaryItem, type: TileType.TEXT, guessed: false })),
                ...words.map((dictionaryItem) => ({ ...dictionaryItem, type: TileType.IMAGE, guessed: false }))
            ].sort(shuffle);
            this.tries = 0;
            this.started = 0;
            this.revealed = [];
            tileTimer && clearTimeout(tileTimer);
            tileTimer = null;
            gameTimer && clearTimeout(gameTimer);
            gameTimer = null;
            this.timeLeft = TIME_TILL_LOST;
        },

        clearRevealed () {
            tileTimer && clearTimeout(tileTimer);
            tileTimer = null;
            this.revealed = [];
        },
        updateGameTime () {
            console.log('updateGameTime');
            const now = new Date().getTime();
            this.timeLeft = TIME_TILL_LOST - Math.floor((now - this.started) / 1000);
            if (this.timeLeft > 0) {
                gameTimer && clearTimeout(gameTimer);
                gameTimer = setTimeout(this.updateGameTime, 1000);
            } else {
                this.state = State.ENDED;
                tileTimer && clearTimeout(tileTimer);
                tileTimer = setTimeout(this.clearRevealed, TILE_DELAY);
            }
        },
        onClick (index: number) {
            if (this.tiles[index].guessed || this.state === State.ENDED || tileTimer) {
                return;
            }
            if (this.state === State.INITIAL) {
                this.state = State.IN_GAME;
            }
            if (this.revealed.length === 2 || this.revealed.includes(index)) {
                this.clearRevealed();
            }
            this.revealed.push(index);
            if (this.revealed.length === 2) {
                this.tries++;
                if (this.tiles[this.revealed[0]].trItem === this.tiles[this.revealed[1]].trItem) {
                    this.tiles[this.revealed[0]].guessed = true;
                    this.tiles[this.revealed[1]].guessed = true;
                }
                tileTimer && clearTimeout(tileTimer);
                tileTimer = setTimeout(this.clearRevealed, TILE_DELAY);
            }
            if (!this.anyLeftToGuess) {
                this.state = State.ENDED;
            }
        }
    }
});
