import { createApp } from 'vue';
import App from './App.vue';
import routerFactory, { GameType } from './router';
import storeFactory from './store';
import { t } from './filters/t';
import { timestampToHhMmSs } from './filters/timestampToHhMmSs';

import './assets/styles/index.scss';
import { fetchDictionary } from './services/dictionary';
const games = document.getElementsByClassName('eturecki-game');
const locale = document.location.pathname.includes('/en/')
    ? 'en'
    : 'pl';

Array.from(games)
    .forEach(async (gameEl) => {
        const type = gameEl.getAttribute('data-type');
        const game = createApp(App);
        game.config.globalProperties.$filters = {
            t: t(locale),
            timestampToHhMmSs
        };
        const store = storeFactory(type as GameType);
        game
            .use(store)
            .use(routerFactory(type as GameType))
            .mount(gameEl);

        if (type === GameType.DICTIONARY || type === GameType.MEMORY) {
            const workbook = await fetchDictionary();
            store.commit('setWorkbook', workbook);
        }
    });

// scaling captivate content:

const iframes = Array.from(document.getElementsByTagName('iframe'));
const scaleIframes = () => {
    iframes.forEach((iframe) => {
        const parentElement = iframe.parentElement;
        if (!parentElement) {
            return;
        }
        const parentWidth = parentElement.offsetWidth;
        const parentCurrentStyle = (parentElement as any).currentStyle || window.getComputedStyle(parentElement);
        const parentHorizontalPaddings = {
            left: parseInt(parentCurrentStyle.paddingLeft, 10), // assuming px
            right: parseInt(parentCurrentStyle.paddingRight, 10)
        };
        const parentInnerWidth = parentWidth - parentHorizontalPaddings.left - parentHorizontalPaddings.right;

        const iframeNativeWidth = Number(iframe.getAttribute('width'));
        const iframeNativeHeight = Number(iframe.getAttribute('height'));

        if (parentInnerWidth !== iframeNativeWidth) {
            const scaleFactor = parentInnerWidth / iframeNativeWidth;
            if (scaleFactor < 1) {  // for now, just scaling down
                // const iframeCurrentStyle = (iframe as any).currentStyle || window.getComputedStyle(iframe);
                // const iframeVerticalMargins = {
                //    top: parseInt(iframeCurrentStyle.marginTop), // assuming px
                //    bottom: parseInt(iframeCurrentStyle.marginBottom)
                // };
                iframe.style.transform = `scale(${scaleFactor})`;
                const iframeScaledHeight = iframeNativeHeight * scaleFactor;
                iframe.style.marginTop = (iframeScaledHeight - iframeNativeHeight) / 2 + 20 + 'px';
                iframe.style.marginBottom = (iframeScaledHeight - iframeNativeHeight) / 2 + 20 + 'px';
                if (scaleFactor < 1) {
                    iframe.style.marginLeft = -(iframeNativeWidth - parentInnerWidth) / 2 + 'px';
                } else {
                    iframe.style.marginLeft = '';
                }
                // console.log(iframe.style.marginTop, iframe.style.marginBottom);
            }
        }
    });
};
window.addEventListener('resize', scaleIframes);
scaleIframes();