const pad = (value: number, positions = 2) => {
    const valueAsStr = value.toString();
    const missingPositions = positions - valueAsStr.length;
    if (missingPositions > 0) {
        return `${new Array(missingPositions).fill(0).join('')}${valueAsStr}`;
    } else {
        return valueAsStr;
    }
};

export const timestampToHhMmSs = (timestamp: number) => {
    const seconds = timestamp / 1000;
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor((seconds - hh * 3600) / 60);
    const ss = Math.floor(seconds % 60);
    return `${pad(hh)}:${pad(mm)}:${pad(ss)}`;
};