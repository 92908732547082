<template>
    <div>
        <et-illustration class="pull-right"
        :image="dictionaryItem.image" />

        <et-word
        :lang="'pl'"
        :word="dictionaryItem.plItem" />

        <et-word-translation
        :lang="'tr'"
        :word="dictionaryItem.trItem"
        :sound="dictionaryItem.trSound" />

        <et-sentences
        :from-lang="'pl'"
        :to-lang="'tr'"
        :sentences="dictionaryItem.sentences" />
    </div>
</template>

<script>
import Word from '../Word.vue';
import WordTranslation from '../WordTranslation.vue';
import Sentences from '../Sentences.vue';
import Illustration from '../Illustration.vue';

export default {
    name: 'et-card-tr-pl',
    components: {
        'et-word': Word,
        'et-word-translation': WordTranslation,
        'et-illustration': Illustration,
        'et-sentences': Sentences
    },
    props: {
        dictionaryItem: { type: Object, required: true }
    }
};
</script>