<template>
    <div>
        <et-illustration class="pull-right"
        v-if="image"
        :image="image" />
        <span class="pull-right placeholder"
        v-else />

        <et-word
        :lang="'tr'"
        :word="dictionaryItem.trItem"
        :sound="dictionaryItem.trSound" />

        <p>
            <label for="answer" class="et-label">
                {{ $filters.t('Enter translation') }}
            </label>
            <input type="text" name="answer" class="et-input" placeholder="?"
            v-model="answer" />

            <span
            v-if="answer.trim()">
                <template
                v-if="distanceFromAnswer > 3">
                    …
                </template>
                <template
                v-else-if="distanceFromAnswer > 0">
                    {{ $filters.t('Getting close…') }}
                </template>
                <template
                v-else>
                    ✔ {{ $filters.t('Perfect!') }}
                </template>
            </span>
        </p>

        <!-- <et-word-translation
        v-else
        :lang="'pl'"
        :word="dictionaryItem.plItem" /> -->

        <et-sentences
        :from-lang="'tr'"
        :to-lang="'pl'"
        :sentences="sentences" />
    </div>
</template>

<script>
import Word from '../Word.vue';
import Sentences from '../Sentences.vue';
import Illustration from '../Illustration.vue';

function lcs (str1, str2, m, n) {
    if (m === 0) {
        return n;
    } else if (n === 0) {
        return m;
    }
    const L = new Array(m + 1);

    let i;
    let j;

    for (i = 0; i <= m; i++) {
        L[i] = new Array(n + 1);
        for (j = 0; j <= n; j++) {
            L[i][j] = 0;
        }
    }

    // Following steps build L[m+1][n+1] in
    // bottom up fashion. Note that L[i][j]
    // contains length of LCS of str1[0..i-1]
    // and str2[0..j-1]
    for (i = 0; i <= m; i++) {
        for (j = 0; j <= n; j++) {
            if (i === 0 || j === 0) {
                L[i][j] = 0;
            } else if (str1[i - 1] === str2[j - 1]) {
                L[i][j] = L[i - 1][j - 1] + 1;
            } else {
                L[i][j] = Math.max(L[i - 1][j], L[i][j - 1]);
            }
        }
    }

    // L[m][n] contains length of LCS
    // for X[0..n-1] and Y[0..m-1]
    return L[m][n];
}

export default {
    name: 'et-card-question-tr-pl',
    components: {
        'et-word': Word,
        // 'et-word-translation': WordTranslation,
        'et-illustration': Illustration,
        'et-sentences': Sentences
    },
    props: {
        dictionaryItem: { type: Object, required: true }
    },
    data () {
        return {
            image: '',
            answer: ''
        };
    },
    computed: {
        answerCorrect () {
            return this.answer.trim() === this.dictionaryItem.plItem;
        },
        distanceFromAnswer () {
            const m = this.answer.trim().length;
            const n = this.dictionaryItem.plItem.length;
            if (!m || !n) {
                return Math.max(m, n);
            }
            const longestCommonString = lcs(this.answer, this.dictionaryItem.plItem, m, n);
            return m + n - 2 * longestCommonString;
        },
        sentences () {
            return this.answerCorrect
                ? this.dictionaryItem.sentences
                : this.dictionaryItem.sentences
                    .map((sentence) => ({ tr: sentence.tr, trSound: sentence.trSound })); // don't show Polish version
        }
    },
    watch: {
        answerCorrect () {
            if (this.answerCorrect) {
                this.image = this.dictionaryItem.image;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.placeholder {
    margin: 2rem;
    width: 10rem;
    height: 10rem;
    background: white;
    position: relative;
    border-radius: 2rem;

    &::after {
        content: '?';
        font-size: 500%;
        color: #ccc;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.et-input {
    margin-right: 0.75em;
}
</style>